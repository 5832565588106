import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { TypeAnimation } from 'react-type-animation';
import { IoCopySharp } from 'react-icons/io5';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

async function query(data) {
  const response = await fetch(
    "https://darija.deepleaf.io/translate",
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const result = await response.json();
  return result;
}

async function submit(data) {
  const response = await fetch(
    "https://darija.deepleaf.io/submit_entry",
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const result = await response.json();
  return result;
}

async function getEntries(apikey) {

  const response = await fetch(
    `https://darija.deepleaf.io/get_entries?api_key=${apikey}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const result = await response.json();
  return result;
}

function UserForm({ onFormSubmit }) {
  const styles = {
    userFormContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      marginBottom: "1rem",
    },
    formIntroduction: {
      marginBottom: "1rem",
      // gradient background
      background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)",
      padding: "1rem",
      borderRadius: "4px",
    },
    formDescription: {
      marginBottom: "1rem",
      fontSize: "1rem",
      color: "gray",
      lineHeight: "1.5",
    },
    formDescriptionPrivacy: {
      marginBottom: "1rem",
      fontSize: ".7rem",
      color: "#333",
      lineHeight: "1.5",
    },
    textarea: {
      flex: "1",
      width: "100%",
      resize: "vertical",
      minHeight: "3rem",
      padding: "0.5rem",
      border: "0px solid transparent", // Updated border style
      background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)",
      borderRadius: "4px",
      transition: "border-color 0.3s ease", // Add transition effect for hover
      outline: "none",
    },
    button: {
      padding: "0.5rem 1rem",
      marginTop: "1rem",
      border: "none",
      borderRadius: "4px",
      background: "linear-gradient(90deg, #32CD32 0%, #32CD32 50%, rgba(255,255,255,0) 100%)",
      color: "#fff",
      textAlign: "left",
      cursor: "pointer",
      fontSize: "25px",
      width: "100%",
    },
    input: {
      padding: "0.5rem",
      border: "0px solid transparent",
      borderRadius: "4px",
      outline: "none",
      fontSize: "16px",
      background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)",
      width: "100%",
    },
    inputDiv: {
      marginBottom: "1rem",
      width: "100%",
    },
    errorDiv: {
      background:"linear-gradient(90deg, red 0%, red 50%, rgba(255,255,255,0) 100%)",
      padding: "0rem 1.5rem",
      borderRadius: "4px",
    },
    errorText: {
      color: "white",
      fontSize: "1rem",
      marginBottom: "1rem",
    },

  };

  const [errorText, setErrorText] = useState("");

  const handleSubmit = async () => {
    try {
      if (!fullName || !email || !phoneNumber || !reason) {
        alert("Please fill out all fields");
        setErrorText("Please fill out all fields");
        return;
      }
      if (!email.includes("@")) {
        alert("Please enter a valid email address");
        setErrorText("Please enter a valid email address");
        return;
      }
      if (phoneNumber.length < 10) {
        alert("Please enter a valid phone number");
        setErrorText("Please enter a valid phone number");
        return;
      }
      if (reason.length < 5) {
        alert("Please enter a valid reason");
        setErrorText("Please enter a valid reason");
        return;
      }
      if (fullName.length < 5) {
        alert("Please enter your first name and last name.");
        setErrorText("Please enter your first name and last name.");
        return;
      }
      const response = await submit({
        full_name: fullName,
        email: email,
        phone_number: phoneNumber,
        reason: reason,
      });
      setErrorText("");
      console.log(response);
      onFormSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [reason, setReason] = useState("");

  return (
    <div style={styles.userFormContainer} >
      <motion.div style={styles.formIntroduction} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 1.5 }}>
        <p style={styles.formDescription}>Hi there! 👋 Welcome to DeepLeaf's 🌿 English to Moroccan 🇲🇦 Dialect Translator BETA. We are thrilled to have you here.</p>
        <p style={styles.formDescription}>To get started, please provide us with some basic information and tell us the reasons why you want to use our translator.</p>
      </motion.div>

      <motion.div style={styles.inputDiv} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 2 }}>
        <input type="text" style={styles.input} value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Full Name" />
      </motion.div>

      <motion.div style={styles.inputDiv} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 2.5 }}>
        <input type="email" style={styles.input} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
      </motion.div>
      <motion.div style={styles.inputDiv} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 3 }}>
        <input type="tel" style={styles.input} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />
      </motion.div>
      <motion.div style={styles.inputDiv} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 3.5 }}>
        <textarea style={{ ...styles.input, ...styles.textarea }} value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Reason why you used this app" />
      </motion.div>
      {errorText && (
      <motion.div style={styles.errorDiv} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 0.5 }}>
        <p style={styles.errorText}>⚠️  {errorText}</p>
      </motion.div>
      )
      }
      <motion.div style={styles.inputDiv} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 4 }}>
        <button style={styles.button} onClick={handleSubmit}>Send Form & Start Translating</button>
      </motion.div>

      <motion.div style={styles.inputDiv} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 4.5 }}>
        <p style={styles.formDescriptionPrivacy}>By sharing your thoughts with us, you will help us improve our translator and deliver a better experience to you and many others. Rest assured, your information is safe with us and will only be used for research and development purposes. We won't share it with any other parties.</p>
      </motion.div>
    </div>

  )
}

function App() {
  const [inputText, setInputText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [height, setHeight] = useState(window.innerHeight - 4 * 16);
  const [formFilled, setFormFilled] = useState(false);
  const [apiKey, setApiKey] = useState(false);
  const [entries, setEntries] = useState([]);

  // Check local storage if the form has been filled
  useEffect(() => {
    const isFormFilled = localStorage.getItem("formFilled");
    setFormFilled(!!isFormFilled);
  }, []);

  useEffect(() => {
    // Animation on load (fade-in effect)
    const fadeInAnimation = () => {
      const appElement = document.getElementById("app");
      appElement.style.animation = "fadeIn 1s ease-in";
    };

    fadeInAnimation();

    // Add event listener for window resize
    const handleResize = () => {
      setHeight(window.innerHeight - 4 * 16);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    inputText,
    translatedText,
    height,
  ]);
  const styles = {
    app: {
      fontFamily: "Arial, sans-serif",
      margin: "0",
      padding: "2rem",
      borderRadius: "8px",
      minHeight: window.innerHeight - 8 * 16,
      maxWidth: "100%",

    },
    container: {
      position: "relative",
      minHeight: window.innerHeight - 8 * 16,
      padding: "2rem",
      background: "linear-gradient(135deg, #32CD32, #a6f8a6)", // Gradient background
      boxShadow: "0 10px 30px rgba(0, 178, 255, 0.2)", // Glass effect
      borderRadius: "8px",
    },
    title: {
      textAlign: "left",
      fontSize: "1.5rem",
      fontFamily: "Arial tahoma, sans-serif",
      color: "black", // Title text color
    },
    translationContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      marginBottom: "1rem",
    },
    textarea: {
      flex: "1",
      width: "100%",
      resize: "vertical",
      minHeight: "3rem",
      padding: "0.5rem",
      border: "0px solid #ccc",
      backgroundColor: "transparent",
      borderRadius: "4px",
      transition: "border-color 0.3s ease", // Add transition effect for hover
      outline: "none",
    },
    button: {
      padding: "0.5rem 1rem",
      marginTop: "1rem",
      border: "none",
      borderRadius: "4px",
      backgroundColor: "#32CD32", // Button color #32CD32
      color: "#fff",
      textAlign: "left",
      cursor: "pointer",
    },
    resultContainer: {
      marginTop: "1rem",
      textAlign: "right", // RTL text alignment
      color: "black", // Text color
      WebkitFontSmoothing: "antialiased", // Add font smoothing for smoother text
      MozOsxFontSmoothing: "grayscale",
    },
    footer: {
      position: "absolute",
      bottom: "1rem",
      marginTop: "1rem",
      padding: "0.5rem 1rem",
      fontSize: "0.8rem",
      textAlign: "right", // RTL text alignment
      color: "black", // Text color
      WebkitFontSmoothing: "antialiased", // Add font smoothing for smoother text
      MozOsxFontSmoothing: "grayscale",
    },
    table: {
      borderCollapse: "collapse",
      width: "100%",
    },
    tr: {
      borderBottom: "1px solid #ccc",
    },
    th: {
      textAlign: "left",
      padding: "0.5rem 1rem",
      borderBottom: "1px solid black",
    },
  };
  const handleTranslation = async () => {
    try {
      setTranslatedText("");
      const response = await query({ "input_text": inputText });
      setTranslatedText(response?.["translation"] || "Try again in a few minutes");
      console.log("Translation response:", response);
    } catch (error) {
      console.error("Translation error:", error);
      setTranslatedText("Error occurred during translation");
    }
  };

  const handleFormSubmit = () => {
    // Set the formFilled flag in local storage when the form is successfully submitted
    localStorage.setItem("formFilled", "true");
    setFormFilled(true);
  };

  const handleGetEntries = async () => {
    try {
      const response = await getEntries(apiKey);
      setEntries(response?.entries);
      console.log("Entries response:", entries);
    } catch (error) {
      console.error("Entries error:", error);
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={
    <div style={styles.app}
    >
      <motion.div id="app" style={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.img src="/DeepLeaf_logo.png" alt="Logo of DeepLeaf" width="100px"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        />
        <motion.h1 style={styles.title}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          English to Darija Translation
        </motion.h1>

        {!formFilled && <UserForm onFormSubmit={handleFormSubmit} />}

        {formFilled &&
          <motion.div style={styles.translationContainer}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1.5 }}
          >
            <motion.textarea
              style={styles.textarea}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Enter your English text here"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 2 }}
            />
            <motion.button style={styles.button} onClick={handleTranslation}
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 2.5 }}
            >
              Translate
            </motion.button>
          </motion.div>
        }
        {/* Add animation for translated text */}
        <motion.div
          initial={{ opacity: 0, y: 20, scale: 0.9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          transition={{ duration: 1, delay: 3 }}
          style={styles.resultContainer}
        >
          {translatedText &&
            <>
              <button style={styles.button} onClick={() => {
                navigator.clipboard.writeText(translatedText);
              }}>
                <IoCopySharp />
              </button> <br></br>
              <br></br>
              <TypeAnimation
                sequence={[translatedText]}
                wrapper="span"
                cursor={true}
                style={{ fontSize: '2em', display: 'inline-block' }}
              />
              <img src="/morshida.png" alt="Morshida character" height="50px" />

            </>}
        </motion.div>
        {/* Add animation for footer */}
        <motion.div
          initial={{ opacity: 0, y: 20, scale: 0.9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          transition={{ duration: 1, delay: 3 }}
          style={styles.footer}
        >
          Made by El Mahdi Aboulmanadel, DeepLeaf 2023 ©.
        </motion.div>
      </motion.div>
    </div>
  } />
      <Route path="/admin" element={
        <div>
          <div style={styles.app}
    >
      <motion.div id="app" style={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.img src="/DeepLeaf_logo.png" alt="Logo of DeepLeaf" width="100px"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        />
        <motion.h1 style={styles.title}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          English to Darija Translation
        </motion.h1>

        <motion.div
          initial={{ opacity: 0, y: 20, scale: 0.9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          transition={{ duration: 1, delay: 3 }}
          style={styles.resultContainer}
        >        
          <input type="text" placeholder="Enter your password" onChange={(e) => {
            setApiKey(e.target.value);
          }} />
          <button style={styles.button} onClick={handleGetEntries}>
            Get Entries
          </button>
          <br></br>
          <br></br>
          <div style={styles.entriesContainer}>
            {/* Show entries in a table (full name, email, phone number, reason) */}
            {
              entries && <table style={styles.table}>
                <thead>
                <tr style={styles.tr}>
                  <th style={styles.th}>Full Name</th>
                  <th style={styles.th}>Email</th>
                  <th style={styles.th}>Phone Number</th>
                  <th style={styles.th}>Reason</th>
                </tr>
                </thead>
                <tbody>
                {entries.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.full_name}</td>
                    <td>{entry.email}</td>
                    <td>{entry.phone_number}</td>
                    <td>{entry.reason}</td>
                  </tr>
                ))}
                </tbody>
            </table>
            }
          </div>
        </motion.div>
        {/* Add animation for footer */}
        <motion.div
          initial={{ opacity: 0, y: 20, scale: 0.9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          transition={{ duration: 1, delay: 3 }}
          style={styles.footer}
        >
          Made by El Mahdi Aboulmanadel, DeepLeaf 2023 ©.
        </motion.div>
      </motion.div>
    </div>
        </div>
      } />
      </Routes>
    </Router>
  );
}

export default App;
